class Elementor_Product {
    static instance;

    static getInstance() {
        if (!Elementor_Product.instance) {
            Elementor_Product.instance = new Elementor_Product();
        }
        return Elementor_Product.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        

        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-cart.default', ( $scope ) => {

            var $wap = $("#page");

            if( !$wap.hasClass('c-offcanvas-content-wrap') ){
                $wap.addClass('c-offcanvas-content-wrap');
            }
            var $button = $( $scope ).find( '.elementor-dropdown-fixed-right .cart-button' ); 

            if( $button ){    
                $( '#'+$button.data('container') ).offcanvas( {
                    modifiers: $button.data('appear'),
                    triggerButton: "#"+$button.attr('id')
                } );  
            }
        } );

        // product wiget
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-products.default', ($scope) => { 
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){  
          
                Elementor_Opal_Slick_Slider.play( $scope, '.products' );
            }
           // carouselFuncs( $scope );
        });

        // product wiget
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-producttabs.default', ($scope) => {
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){
                Elementor_Opal_Slick_Slider.play( $scope, '.products' );
            }
        });


        elementorFrontend.hooks.addAction('frontend/element_ready/opal-productdeals.default', ($scope) => {
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){
                Elementor_Opal_Slick_Slider.play( $scope, '.products' );
            }
        });

        elementorFrontend.hooks.addAction('frontend/element_ready/opal-categoryproducttab.default', ($scope) => { 
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){
                Elementor_Opal_Slick_Slider.play( $scope, '.products' );
            }
        });

        elementorFrontend.hooks.addAction('frontend/element_ready/opal-megaproducts.default', ($scope) => {  
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){ 
                Elementor_Opal_Slick_Slider.play( $scope, '.products' );
            }
        });


        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-productcategories.default', ( $scope ) => {
              // product wiget
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){  
                Elementor_Opal_Slick_Slider.play( $scope, '.row-items' );
            }
        } );

        ///// productsmasonry ////// 
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-productsmasonry.default', ( $scope ) => {
              // product wiget
            var $container = $( '.elementor-masonry-style', $scope );
            var resizeBox = function ( $container ){
                var h=0; 
                $container.find('li.product').each( function(){
                    var b = $(this);  
                    if ( h < b.height() ) h = b.height();
                } ); 
           
                $container.find('.elementor-items-container li.product').height( h );
            }


          //  console.log( $container.html() );
          //   $container.find('first').removeClass('first');
            // initialize Masonry after all images have loaded
            $container.find('.elementor-items-container').imagesLoaded(function() {  
               //  resizeBox( $container );
                $container.find('.elementor-items-container').isotope({
                  
                    isOriginLeft: ! $('body').hasClass('rtl'),
                    itemSelector: 'li.product'
                });
            });
            $('.isotype-filter a', $container ).click( function(){

                $container.find('.elementor-items-container').isotope({
                    filter:'.'+$(this).data( 'filter' )  
                });
                return false; 
            } );

       
          
        } );


    }
}

Elementor_Product.getInstance();

