class Elementor_Team{
    static instance;

    static getInstance() {
        if (!Elementor_Team.instance) {
            Elementor_Team.instance = new Elementor_Team();
        }
        return Elementor_Team.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-teams.default', ( $scope ) => {
                // product wiget
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){  
                Elementor_Opal_Slick_Slider.play( $scope, '.row-items' );
            }

        } );
    }
}


Elementor_Team.getInstance();