class Elementor_Opal_Slick_Slider {
    static play( $scope , $selector ) {
        var slidesToShow = 0;
        var elementSettings =  $scope.data('settings');
        if( elementSettings === undefined ){
            var   elementSettings =  $scope.find(".elementor-opal-slick-slider").data('settings');
        }
        //   console.log( elementSettings );
        var slidesToShow =+ elementSettings.slides_to_show || 3,
        isSingleSlide = 1 === slidesToShow,
        breakpoints = elementorFrontend.config.breakpoints;

        var slickOptions = {
            slidesToShow: slidesToShow,
            autoplay: 'yes' === elementSettings.autoplay,
            autoplaySpeed: elementSettings.autoplay_speed,
            infinite: 'yes' === elementSettings.infinite,
            pauseOnHover: 'yes' === elementSettings.pause_on_hover,
            speed: elementSettings.speed,
            arrows: -1 !== [ 'arrows', 'both' ].indexOf( elementSettings.navigation ),
            dots: -1 !== [ 'dots', 'both' ].indexOf( elementSettings.navigation ),
            rtl: 'rtl' === elementSettings.direction,
            responsive: [
                {
                    breakpoint: breakpoints.lg,
                    settings: {
                        slidesToShow: +elementSettings.slides_to_show_tablet || ( isSingleSlide ? 1 : 2 ),
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: breakpoints.md,
                    settings: {
                        slidesToShow: +elementSettings.slides_to_show_mobile || 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };

        if ( isSingleSlide ) {
            slickOptions.fade = 'fade' === elementSettings.effect;
        } else {
            slickOptions.slidesToScroll = +elementSettings.slides_to_scroll;
        }

        let $carousel = $scope.find( $selector );
       //  $carousel.removeClass('products');
        $carousel.slick( slickOptions );
       //  $carousel.addClass('products');
    }
}