class Elementor_Image_Gallery {
    static instance;

    static getInstance() {
        if (!Elementor_Image_Gallery.instance) {
            Elementor_Image_Gallery.instance = new Elementor_Image_Gallery();
        }
        return Elementor_Image_Gallery.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-image-gallery.default', ($scope) => {
            
            let $iso = $scope.find('.isotope-grid');
            let currentIsotope = $iso.isotope({filter: '*'});
            $scope.find('.elementor-galerry__filters li').on('click', function () {
                $(this).parents('ul.elementor-galerry__filters').find('li.elementor-galerry__filter').removeClass('elementor-active');
                $(this).addClass('elementor-active');
                let selector = $(this).attr('data-filter');
                currentIsotope.isotope({filter: selector});
            });
            currentIsotope.imagesLoaded(function () {
                $iso.isotope();
                $iso.removeClass('gallery-visibility');
            });
            $scope.find('.isotope-grid .grid__item:visible').magnificPopup({
                delegate : 'a',
                type     : 'image',
                tLoading : 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery  : {
                    enabled           : true,
                    navigateByImgClick: true,
                    preload           : [0, 1]
                },
                image    : {
                    tError  : '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function (item) {
                        return item.el.attr('title');
                    }
                }
            });
            if ($scope.find('.active-infinite-scroll').length) {
                $(window).bind('scroll', () => {
                    if ($(window).scrollTop() >= currentIsotope.offset().top + currentIsotope.outerHeight() - window.innerHeight + 40) {
                        let $data_container = $('.gallery-item-load');
                        let data = $data_container.data('gallery');
                        if (data.length) {
                            $data_container.addClass('opal-loading');
                            let htmlCode = '';
                            data[0].forEach((element) => {
                                htmlCode += this.renderItem(element);
                            })
                            let $elem = $(htmlCode);
                            currentIsotope
                                .append($elem)
                                .isotope('appended', $elem)
                                .isotope('layout');
                            currentIsotope.imagesLoaded(function () {
                                $iso.isotope();
                                setTimeout(function(){
                                    $data_container.removeClass('opal-loading');
                                }, 500);
                                
                                $scope.find('.isotope-grid .grid__item:visible').magnificPopup({
                                    delegate : 'a',
                                    type     : 'image',
                                    tLoading : 'Loading image #%curr%...',
                                    mainClass: 'mfp-img-mobile',
                                    gallery  : {
                                        enabled           : true,
                                        navigateByImgClick: true,
                                        preload           : [0, 1]
                                    },
                                    image    : {
                                        tError  : '<a href="%url%">The image #%curr%</a> could not be loaded.',
                                        titleSrc: function (item) {
                                            return item.el.attr('title');
                                        }
                                    }
                                });
                            });
                            data.shift();
                            $data_container.attr('data-gallery', JSON.stringify(data));
                        }
                    }
                });
            }
        });
    }

    renderItem(images) {
        return `<div class="column-item grid__item masonry-item __all gallery_group_${images['group']}">
                    <a data-elementor-open-lightbox="no" href="${images['url']}">
                                <img src="${images['thumbnail_url']}" alt=""/>
                                <div class="gallery-item-overlay">
                                    <i class="opal-icon-zoom"></i>
                                </div>
                            </a>
                 </div>`;
    }
}

Elementor_Image_Gallery.getInstance();

