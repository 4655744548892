class Elementor_Text_Carousel{
    static instance;

    static getInstance() {
        if (!Elementor_Text_Carousel.instance) {
            Elementor_Text_Carousel.instance = new Elementor_Text_Carousel();
        }
        return Elementor_Text_Carousel.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        

        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-text_carousel.default', ( $scope ) => {
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){ 
                Elementor_Opal_Slick_Slider.play( $scope, '.row-slick-items' );
            }
        } );
    }
}
Elementor_Text_Carousel.getInstance();