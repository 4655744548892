class Elementor_Tabs{
    static instance;

    static getInstance() {
        if (!Elementor_Tabs.instance) {
            Elementor_Tabs.instance = new Elementor_Tabs();
        }
        return Elementor_Tabs.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){  

        var tabFunc = function ( $scope ){
             $scope.addClass('elementor-widget-tabs');
            let $tabs = $scope.find('.elementor-tabs-wrapper');
            let $contents = $scope.find('.elementor-tabs-content-wrapper');
            $tabs.find('.elementor-tab-title').first().click();

            // Active tab
            $contents.find('.elementor-active').show( );

            $tabs.find('.elementor-tab-title').on('click', function () {  
                $tabs.find('.elementor-tab-title').removeClass('elementor-active');
                $contents.find('.elementor-tab-content').removeClass('elementor-active').hide( );
                $(this).addClass('elementor-active');
                let id = $(this).attr('aria-controls');
                $contents.find('#'+ id).addClass('elementor-active').show();
                if( $( $contents).find(".elementor-slick-slider").length > 0 ){
                    $($contents ).find( '.slick-slider' ).slick('setPosition'); 
                }

                var fix = $(this).data('fix-slider');
                if( $( $contents).find(".flexslider-loaded").length &&  fix != 1 ){
                    $('.product-block .product-img-wrap', $contents ).resize(); 
                    $(this).data('fix-slider', 1 );
                } 
            })
        }

        // opal tab widget 
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-tabs.default', ( $scope ) => { 
            tabFunc( $scope ); 
        } );

        // product tab widgets
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-producttabs.default', ( $scope ) => { 
            tabFunc( $scope ); 
        } );

        elementorFrontend.hooks.addAction('frontend/element_ready/opal-categoryproducttab.default', ($scope) => { 
            tabFunc( $scope );
        });
}
}
Elementor_Tabs.getInstance();