class Elementor_Opal_Counter{

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-counter.default', ( $scope ) => {
            $scope.find('.counter').each(function() {
                $(".counter").countimator();
                
            });
        } );
    }
}
new Elementor_Opal_Counter();