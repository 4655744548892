class Elementor_Opal_Timeline{
    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-timeline.default', ( $scope, $ ) => {
            if ( 'undefined' == typeof $scope )
            return;
            // Define variables.
            var $this               = $scope.find( '.opal-timeline-node' );
            var timeline_main       = $scope.find(".opal-timeline-main");
            var animate_border      = $scope.find(".animate-border");
            var timeline_icon       = $scope.find(".opal-timeline-marker");
            var opal_events         = $scope.find(".opal-events");
            var events_inner        = $scope.find(".opal-timeline-widget");
            var line_inner          = $scope.find(".opal-timeline__line__inner");
            var line_outer          = $scope.find(".opal-timeline__line");
            var $main_class         = $scope.find(".elementor-widget-opal-timeline");
            var $icon_class         = $scope.find(".opal-timeline-marker");
            var $card_last          = $scope.find(".opal-timeline-field:last-child");

            var timeline_start_icon = $icon_class.first().position();
            var timeline_end_icon = $icon_class.last().position();

            line_outer.css('top', 0 );

            var timeline_card_height = $card_last.height();

            var last_item_top = $card_last.offset().top - $this.offset().top;

            var $last_item, parent_top;

            if( $scope.hasClass('opal-timeline-arrow-center')) {

                line_outer.css('bottom', 0 );

                parent_top = last_item_top + timeline_start_icon.top;
                $last_item = parent_top + timeline_end_icon.top;

            } else if( $scope.hasClass('opal-timeline-arrow-top')) {

                var top_height = timeline_card_height - timeline_end_icon.top;
                line_outer.css('bottom', top_height );

                $last_item = last_item_top;

            } else if( $scope.hasClass('opal-timeline-arrow-bottom')) {

                var bottom_height = timeline_card_height - timeline_end_icon.top;
                line_outer.css('bottom', bottom_height );

                parent_top = last_item_top - timeline_start_icon.top;
                $last_item = parent_top + timeline_end_icon.top;

            }

            var viewportHeight = document.documentElement.clientHeight;
            var elementPos = $this.offset().top;

            var photoViewportOffsetTop = elementPos - $(document).scrollTop();

            var elementEnd = $last_item + 20;

            var initial_height = 0;

            line_inner.height(initial_height);

            var num = 0;

            // Callback function for all event listeners.
            function opalTimelineFunc() {

                    var $document = $(document);
                    // Repeat code for window resize event starts.
                    timeline_start_icon = $icon_class.first().position();
                    timeline_end_icon   = $icon_class.last().position();

                    $card_last          = $scope.find(".opal-timeline-field").last();

                    line_outer.css('top', 0 );

                    timeline_card_height = $card_last.height();

                    last_item_top = $card_last.offset().top - $this.offset().top;

                    if( $scope.hasClass('opal-timeline-arrow-center')) {

                        line_outer.css('bottom', 0 );
                        parent_top = last_item_top + timeline_start_icon.top;
                        $last_item = parent_top + timeline_end_icon.top;

                    } else if( $scope.hasClass('opal-timeline-arrow-top')) {

                        var top_height = timeline_card_height - timeline_end_icon.top;
                        line_outer.css('bottom', top_height );
                        $last_item = last_item_top;

                    } else if( $scope.hasClass('opal-timeline-arrow-bottom')) {

                        var bottom_height = timeline_card_height - timeline_end_icon.top;
                        line_outer.css('bottom', bottom_height );
                        parent_top = last_item_top + timeline_start_icon.top;
                        $last_item = parent_top + timeline_end_icon.top;
                    }
                    elementEnd = $last_item + 40;

                // Repeat code for window resize event ends.

                var viewportHeight = document.documentElement.clientHeight;
                var viewportHeightHalf = viewportHeight/2;
                var elementPos = $this.offset().top;
                var new_elementPos = elementPos + timeline_start_icon.top;

                var photoViewportOffsetTop = new_elementPos - $document.scrollTop();

                if (photoViewportOffsetTop < 0) {
                    photoViewportOffsetTop = Math.abs(photoViewportOffsetTop);
                } else {
                    photoViewportOffsetTop = -Math.abs(photoViewportOffsetTop);
                }

                if ( elementPos < (viewportHeightHalf) ) {

                    if ( (viewportHeightHalf) + Math.abs(photoViewportOffsetTop) < (elementEnd) ) {
                        line_inner.height((viewportHeightHalf) + photoViewportOffsetTop);
                    }else{
                        if ( (photoViewportOffsetTop + viewportHeightHalf) >= elementEnd ) {
                            line_inner.height(elementEnd);
                        }
                    }
                } else {
                    if ( (photoViewportOffsetTop  + viewportHeightHalf) < elementEnd ) {
                        if (0 > photoViewportOffsetTop) {
                            line_inner.height((viewportHeightHalf) - Math.abs(photoViewportOffsetTop));
                            ++num;
                        } else {
                            line_inner.height((viewportHeightHalf) + photoViewportOffsetTop);
                        }
                    }else{
                        if ( (photoViewportOffsetTop + viewportHeightHalf) >= elementEnd ) {
                            line_inner.height(elementEnd);
                        }
                    }
                }

                var timeline_icon_pos, timeline_card_pos;
                var elementPos, elementCardPos;
                var timeline_icon_top, timeline_card_top;
                timeline_icon = $scope.find(".opal-timeline-marker");
                animate_border  = $scope.find(".animate-border");

                for (var i = 0; i < timeline_icon.length; i++) {

                    timeline_icon_pos = $(timeline_icon[i]).offset().top;
                    timeline_card_pos = $(animate_border[i]).offset().top;

                    elementPos = $this.offset().top;
                    elementCardPos = $this.offset().top;

                    timeline_icon_top = timeline_icon_pos - $document.scrollTop();
                    timeline_card_top = timeline_card_pos - $document.scrollTop();

                    if ( ( timeline_card_top ) < ( ( viewportHeightHalf ) ) ) {

                        animate_border[i].classList.remove("out-view");
                        animate_border[i].classList.add("in-view");

                    } else {
                        // Remove classes if element is below than half of viewport.
                        animate_border[i].classList.add("out-view");
                        animate_border[i].classList.remove("in-view");
                    }

                    if ( ( timeline_icon_top ) < ( ( viewportHeightHalf ) ) ) {

                        // Add classes if element is above than half of viewport.
                        timeline_icon[i].classList.remove("out-view-timeline-icon");
                        timeline_icon[i].classList.add("in-view-timeline-icon");

                    } else {

                        // Remove classes if element is below than half of viewport.
                        timeline_icon[i].classList.add("out-view-timeline-icon");
                        timeline_icon[i].classList.remove("in-view-timeline-icon");

                    }
                }

            }
            // Listen for events.
            window.addEventListener("load", opalTimelineFunc);
            window.addEventListener("resize", opalTimelineFunc);
            window.addEventListener("scroll", opalTimelineFunc);


            var post_selector = $scope.find( '.opal-days' );

            var node_id = $scope.data( 'id' );

            if ( post_selector.hasClass( 'opal-timeline-infinite-load' ) ) {

                post_selector.infinitescroll(
                    {
                        navSelector     : '.elementor-element-' + node_id + ' .opal-timeline-pagination',
                        nextSelector    : '.elementor-element-' + node_id + ' .opal-timeline-pagination a.next',
                        itemSelector    : '.elementor-element-' + node_id + ' .opal-timeline-field',
                        prefill         : true,
                        bufferPx        : 200,
                        loading         : {
                            msgText         : 'Loading',
                            finishedMsg     : '',
                            img             : opal_script.post_loader,
                            speed           : 10,
                        }
                    },
                    function( elements ) {
                        elements = $( elements );
                        window.addEventListener("load", opalTimelineFunc);
                        window.addEventListener("resize", opalTimelineFunc);
                        window.addEventListener("scroll", opalTimelineFunc);
                    }
                );
            }
        } );
    }
}
new Elementor_Opal_Timeline();