class Elementor_Brand{
    static instance;

    static getInstance() {
        if (!Elementor_Brand.instance) {
            Elementor_Brand.instance = new Elementor_Brand();
        }
        return Elementor_Brand.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }
    
    init(){ 
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-brand.default', ( $scope, model ) => { 
            Elementor_Opal_Slick_Slider.play( $scope , '.elementor-image-carousel' ); 
        } );
    }
}
Elementor_Brand.getInstance();