class Opal_Elementor_General {
    static instance;

    static getInstance() {
        if (!Opal_Elementor_General.instance) {
            Opal_Elementor_General.instance = new Opal_Elementor_General();
        }
        return Opal_Elementor_General.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', ( $scope ) => {
            this.init();

            /// 
           // $header_left = $.find('.opalelementor-header-left' );

           // alert( $header_left.width() );

        });
    }

    init() {
 

        /// fixed offcanvas
        if( $(".opalelementor-header-left").length ){
            let device = elementorFrontend.getCurrentDeviceMode();

            let updateFixedSidebar = function (){
                let device = elementorFrontend.getCurrentDeviceMode();
                let sidebar = $(".opalelementor-header-left"); 

                if( sidebar.hasClass('elementor-hidden-phone') && device == 'mobile' ){
                    $('html').css( {'margin-left':0} );
                    sidebar.hide();
                }else if( sidebar.hasClass('elementor-hidden-phone') && device == 'tablet' ){  
                    $('html').css( {'margin-left':0} );
                    sidebar.hide();
                } else {
                    var width = sidebar.width(); 
                    if( sidebar.data('width') ){
                        sidebar.css( {width:sidebar.data('width')} ); 
                        width = sidebar.data('width');
                    }

                   // alert( width );
                    $('html').css( {'margin-left':width} );
                    sidebar.show();
                }
            }
            updateFixedSidebar();
            elementorFrontend.elements.$window.on('resize',  updateFixedSidebar ); 
        }

        elementorFrontend.hooks.addAction('frontend/element_ready/opal-account.default', ($scope) => {
                
                $scope.$toggle = $scope.find('.opal-account-popup-btn');
                if( $scope.$toggle ) {
                    var effect =  $scope.$toggle.data('effect') 

                    $scope.$toggle.magnificPopup({
                        type        : 'inline',
                        removalDelay: 500,
                        overflowY   : 'hidden',
                        callbacks   : {
                            beforeOpen: function () {  
                                this.st.mainClass = effect;
                            },
                            close: function () {
                                 $scope.$toggle.removeClass('opalelementor-active');
                            }
                        },
                        midClick    : true
                    });

                }

                var $wap = $("#page");

                if( !$wap.hasClass('c-offcanvas-content-wrap') ){
                    $wap.addClass('c-offcanvas-content-wrap');
                }
                
                var $button = $( $scope ).find( '.elementor-dropdown-fixed-right .elementor-dropdown-header a' ); 
                if( $button ){   
                    $( '#'+$button.data('container') ).offcanvas( {
                        modifiers: $button.data('appear'),
                        triggerButton: "#"+$button.attr('id')
                    } );  
                }
        });

        /// /*
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-masonry-posts.default', ( $scope ) => {
              // product wiget
            var $container = $( '.elementor-masonry-style', $scope );

          //  console.log( $container.html() );
          //   $container.find('first').removeClass('first');
            // initialize Masonry after all images have loaded
            $container.find('.elementor-items-container').imagesLoaded(function() {  
                $container.find('.elementor-items-container').isotope({
                  
                    isOriginLeft: ! $('body').hasClass('rtl'),
                    itemSelector: '.column-item'
                });
            });
            $('.isotype-filter a', $container).click( function(){

                $container.find('.elementor-items-container').isotope({
                    filter:'.'+$(this).data( 'filter' )  
                });
                return false; 
            } );

        } );
        /////

        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-offcanvas-sb.default', ( $scope ) => {

            var $wap = $("#page");

            if( !$wap.hasClass('c-offcanvas-content-wrap') ){
                $wap.addClass('c-offcanvas-content-wrap');
            }
            var $button = $( $scope ).find( '.c-elementor-button-link' ); 
 
            if( $button ){
                $( '#'+$button.data('container') ).offcanvas( {
                    modifiers: $button.data('appear'),
                    triggerButton: "#"+$button.attr('id')
                } );  

              //  alert(  $( '#'+$button.data('container') ).find('.opalelementor-nav-menu').html() );

                $( '#'+$button.data('container') ).find('.opalelementor-nav-menu').smartmenus({
                      subMenusSubOffsetX: 1,
                        subMenusSubOffsetY: -8,
                    collapsibleBehavior: 'accordion'
                }); 
            }
        } );
    }
}


Opal_Elementor_General.getInstance();

