class ContactFormPopup{
    static instance;

    static getInstance() {
        if (!ContactFormPopup.instance) {
            ContactFormPopup.instance = new ContactFormPopup();
        }
        return ContactFormPopup.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-button-contact7.default', ( $scope ) => {
            $scope.find('.opal-button-contact7 a.elementor-button').magnificPopup({
                type: 'inline',
                removalDelay: 500,
                callbacks: {
                    beforeOpen: function() {
                        this.st.mainClass = this.st.el.attr('data-effect');
                    }
                },
                midClick: true
            });
        } );
    }
}
ContactFormPopup.getInstance();