class Elementor_Testimonial{
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial.instance) {
            Elementor_Testimonial.instance = new Elementor_Testimonial();
        }
        return Elementor_Testimonial.instance;
    }

    constructor(){
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init(){
        elementorFrontend.hooks.addAction( 'frontend/element_ready/opal-testimonials.default', ( $scope ) => {
              // product wiget
            if( $scope.find(".elementor-opal-slick-slider").length > 0 ){  
                Elementor_Opal_Slick_Slider.play( $scope, '.row-items' );
            }
        } );
    }
}


Elementor_Testimonial.getInstance();